<script lang="ts" setup>
import {onMounted, onUnmounted, reactive} from "vue";

const countdown = reactive({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
})

let counterInterval;

onMounted(() => {
  counterInterval = setInterval(() => {
    var countDownDate = new Date("Jun 1, 2024 13:00:00").getTime();
    var now = new Date().getTime();

    var distance = countDownDate - now;

    countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    countdown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    countdown.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000);
  }, 1000);
})

onUnmounted(() => {
  if (counterInterval) {
    clearInterval(counterInterval)
  }
})

function formatNubmer(number: number) {
  if (number < 9) {
    return `0${number}`;
  }

  return number
}
</script>

<template>
  <div class="counter">
    <div>{{ formatNubmer(countdown.days) }}D</div>
    <div>{{ formatNubmer(countdown.hours) }}h</div>
    <div>{{ formatNubmer(countdown.minutes) }}m</div>
    <div>{{ formatNubmer(countdown.seconds) }}s</div>
  </div>
</template>

<style lang="scss">
.counter {
  @apply flex;

  div {
    @apply text-2xl md:text-4xl lg:text-6xl py-2 px-4 md:w-20 md:w-24 lg:w-36 text-center font-mono;
  }
}
</style>
