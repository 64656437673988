<script lang="ts" setup>

</script>

<template>
  <nav>
    <router-link to="/?section=rueckmeldung">
      <span class="line"></span>
      <span>
            Rückmeldung
          </span>
    </router-link>
    <router-link to="/?section=ablauf">
      Ablauf
    </router-link>
    <router-link to="/?section=uebernachtung">
      Übernachtung
    </router-link>
    <router-link to="/?section=anfahrt">
      Anfahrt
    </router-link>
    <router-link to="/?section=kontakte">
      Kontakte
    </router-link>
    <router-link to="/?section=faq">
      FAQ
    </router-link>
  </nav>
</template>

<style lang="scss">
nav {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    color: #ffffff;
    font-size:1.8rem;
    font-weight: 800;
    text-decoration: none;
    padding: 1rem 2rem;
    display: flex;
    cursor: pointer;
    transition: transform 400ms ease-in-out;

    &:hover{
      text-decoration: underline;
      transform: scale(1.1);
    }
  }
}
</style>
