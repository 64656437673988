<script lang="ts" setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";

const isMenuOpen = ref(false);
const route = useRoute()

function scrollToSection(sectionId: any) {
  const sectionElement = document.getElementById(sectionId)

  if (!sectionElement) {
    return
  }

  sectionElement.scrollIntoView({behavior: "smooth", block: "start"});
}

onMounted(() => {
  scrollToSection(route.query.section)
})

watch(
    () => route.query.section,
    sectionId => {
      setTimeout(() => {
        scrollToSection(sectionId)
      },350)
    }
)

</script>

<template>
  <div class="container" :class="{isMenuOpen}">
    <HeaderComponent @click="isMenuOpen= !isMenuOpen"/>
    <main @click="isMenuOpen = false">
      <slot></slot>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.container {
  @apply h-screen grid max-w-full;
  grid-template-columns: 10% 1fr;
  transition: grid-template-columns 300ms;

  @media screen and (max-width: 1279px) {
    &.isMenuOpen {
      grid-template-columns: 300px 1fr;

      :deep(.header-overlay) {
        display: none;
        @apply bg-red-600;
      }
    }
  }

  @screen xl {
    grid-template-columns: 20% 1fr;
  }

}

main {
  background-color: #f6f6f6;
  overflow-y: auto;
  overflow-x: hidden;

  position: relative;
  scroll-behavior: smooth;
}
</style>
