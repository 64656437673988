<script lang="ts" setup>
import {computed, defineProps} from "vue";

const props = defineProps<{
  title: string,
  invert?: boolean
}>()

const id = computed(() => props.title.toLowerCase().replace(' ', '_').replace('ü', 'ue'))

</script>
<template>
  <section :id="id" :class="{ invertedStyle: props.invert }">
    <div class="section-header">
      <div class="line"></div>
      <h2>{{ props.title ?? '' }}</h2>
    </div>

    <slot></slot>
  </section>
</template>

<style lang="scss" scoped>
section {
  @apply min-h-full p-5 md:p-6 lg:p-8 relative;

  .section-header {
    @apply flex items-center whitespace-nowrap z-10 relative;

    .line {
      @apply w-full mr-4 bg-wedding-green;
      @apply h-[.2rem] md:h-[.3rem];
      animation: line 6s ease-in-out infinite alternate;
    }

    h2 {
      @apply uppercase font-semibold text-wedding-green;
      @apply text-3xl sm:text-4xl md:text-5xl;
    }
  }

  &.invertedStyle {
    @apply bg-wedding-green text-white;

    .section-header {
      h2 {
        @apply text-white;
      }

      .line {
        @apply bg-white;
      }
    }
  }
}
</style>
