<script lang="ts" setup>
import {onMounted, ref} from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import {ChevronUpIcon} from '@heroicons/vue/20/solid'

const questionList = ref([])

const getCakes = async () => {
  try {
    const response = await fetch('https://hochzeit.waldmann.studio/api/question');
    questionList.value = await response.json();
  } catch (e) {
    console.error(e)
  }
}

onMounted(() => {
  getCakes()
})
</script>

<template>
  <div>
    <Disclosure v-for="question of questionList" :key="question.question" as="div" v-slot="{ open }"
                class="bg-wedding-green/25 mb-4">
      <DisclosureButton
          class="flex w-full items-center justify-between bg-wedding-green px-4 py-1 lg:py-2 text-left text-xl lg:text-2xl font-medium text-white hover:bg-wedding-green/75"
      >
        <span>{{ question.question }}</span>
        <ChevronUpIcon
            :class="open ? '' : 'rotate-180 transform'"
            class="h-8 lg:h-10 w-8 lg:w-10 text-white"
        />
      </DisclosureButton>
      <DisclosurePanel class="px-4 pb-2 py-2 text-lg lg:text-xl text-gray-700">
        {{ question.answer }}
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<style lang="scss" scoped>

</style>
