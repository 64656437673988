<script lang="ts" setup>
import {onMounted, ref, watch} from "vue";

const question = ref('')
const successful = ref(false)
const error = ref(false)
const form = ref(null)

const onFormSubmit = (event: any) => {
  event.preventDefault();
  sendForm()
}

const sendForm = async () => {
  const body = {
    question: question.value,
  }
  console.log('body', body)

  try {
    const response = await fetch('https://hochzeit.waldmann.studio/api/question', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    successful.value = true;
    setTimeout(() => successful.value = false, 16000)

    question.value = ''
  } catch (e) {
    error.value = true;
    setTimeout(() => error.value = false, 4000)
    console.error(e)
  }
}

</script>

<template>
  <form ref="form" @submit="onFormSubmit">
    <div v-if="successful" class="bg-green-600 text-white py-2 px-4">
      Erfolgreich abgesendet!
    </div>
    <div v-if="error" class="bg-red-600 text-white py-2 px-4">
      Ein Fehler ist aufgetreten. Bitte meldet euch bei uns!
    </div>

    <div class="form-group">
      <div>
        <input v-model="question" type="text" id="question" class="w-full" name="question" placeholder="Deine Frage könnte hier stehen!?"
               required/>
      </div>
    </div>

    <div class="form-group -mt-1">
      <input type="submit" value="Absenden" class="w-full bg-wedding-green text-white py-2 px-4 font-bold text-wh"/>
    </div>
  </form>
</template>

<style lang="scss" scoped>
form, input, textarea {
  @apply text-lg md:text-xl lg:text-2xl;
}

.form-group {
  @apply py-4;
}

input, textarea {
  @apply border-0 active:ring-wedding-green focus:ring-wedding-green;
}
</style>
