<script lang="ts" setup>
import {onMounted, ref, watch} from "vue";

const persons = ref([{name: '', food: 'all'}])
const cake = ref(false)
const cakeName = ref('')
const cakeList = ref([])
const breakfast = ref(false)
const breakfastCount = ref(0)
const miscellaneous = ref('')
const successful = ref(false)
const error = ref(false)
const form = ref(null)

watch(breakfast, async (newBreakfast) => {
  if (newBreakfast) {
    breakfastCount.value = persons.value.length
  }
})

const addPerson = () => {
  persons.value.push({name: '', food: 'all'})
}

const onFormSubmit = (event: any) => {
  event.preventDefault();
  sendForm()
}

const getCakes = async () => {
  try {
    const response = await fetch('https://hochzeit.waldmann.studio/api');
    const data = await response.json();
    cakeList.value = data.filter((x: string) => x)
  } catch (e) {
    console.error(e)
  }
}

const sendForm = async () => {
  const body = {
    persons: persons.value,
    breakfast: breakfast.value ? breakfastCount.value : 0,
    cake: cake.value ? cakeName.value : '',
    miscellaneous: miscellaneous.value
  }

  try {
    const response = await fetch('https://hochzeit.waldmann.studio/api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    successful.value = true;
    setTimeout(() => successful.value = false, 16000)

    persons.value = [{name: '', food: 'all'}]
    cake.value = false
    cakeName.value = ''
    cakeList.value = []
    breakfast.value = false
    breakfastCount.value = 0
    miscellaneous.value = ''
  } catch (e) {
    error.value = true;
    setTimeout(() => error.value = false, 4000)
    console.error(e)
  }
}

onMounted(() => {
  getCakes()
})

</script>

<template>
  <form ref="form" @submit="onFormSubmit">
    <div v-if="successful" class="bg-green-600 text-white py-2 px-4">
      Erfolgreich abgesendet!
    </div>
    <div v-if="error" class="bg-red-600 text-white py-2 px-4">
      Ein Fehler ist aufgetreten. Bitte meldet euch bei uns!
    </div>
    <div class="form-group flex flex-col">
      <div v-for="(person, index) in persons" :key="index" class="flex flex-col md:flex-row items-center mb-4">
        <div class="w-full">
          <input v-model="person.name" type="text" class="w-full min-w-72" :name="`name_${index}`" placeholder="Name"
                 required/>
        </div>

        <div class="grid grid-flow-col justify-stretch w-full mt-2 md:mt-0 md:ml-8">
          <div>
            <input v-model="person.food" type="radio" :id="`all_${index}`" class="hidden peer" :name="`food_${index}`"
                   value="all" checked/>
            <label :for="`all_${index}`" class="radio-label peer-checked:bg-wedding-green peer-checked:text-white">
              Alles
            </label>
          </div>

          <div>
            <input v-model="person.food" type="radio" :id="`vegetarian_${index}`" class="hidden peer"
                   :name="`food_${index}`" value="vegetarian"/>
            <label :for="`vegetarian_${index}`"
                   class="radio-label peer-checked:bg-wedding-green peer-checked:text-white">
              Vegetarisch
            </label>
          </div>

          <div>
            <input v-model="person.food" type="radio" :id="`vegan_${index}`" class="hidden peer" :name="`food_${index}`"
                   value="vegan"/>
            <label :for="`vegan_${index}`" class="radio-label peer-checked:bg-wedding-green peer-checked:text-white">
              Vegan
            </label>
          </div>
        </div>
      </div>

      <div @click="addPerson()" class="mt-1 md:mt-2 py-2 px-6 border border-wedding-green text-center">
        <input type="button" value="Person hinzufügen"/>
      </div>
    </div>

    <div class="form-group">
      <div class="flex flex-row items-center">
        <input v-model="cake" type="checkbox" id="cake" name="cake"
               class="focus:ring-0 checked:bg-wedding-green checked:hover:bg-wedding-green checked:active:bg-wedding-green checked:focus:bg-wedding-green"/>
        <label v-if="persons.length < 2" for="cake" class="ml-3">Ich bringe etwas Gebackenes fürs Kuchenbuffet mit.</label>
        <label v-else for="cake" class="ml-3">Wir bringen etwas Gebackenes fürs Kuchenbuffet mit.</label>
      </div>
      <div v-if="cake" class="mt-3">
        <input v-model="cakeName" type="text" id="cake-name" class="w-full" name="cakeName" placeholder="Gebäckname"
               required/>
        <div v-if="cakeList.length > 0" class="mt-2 italic">
          <p>Bisher angemeldetes:</p>
          <p>{{ cakeList.join(', ') }}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="flex flex-row items-center">
        <input v-model="breakfast" type="checkbox" id="breakfast" name="breakfast"
               class="focus:ring-0 checked:bg-wedding-green checked:hover:bg-wedding-green checked:active:bg-wedding-green checked:focus:bg-wedding-green"/>
        <label v-if="persons.length < 2" for="breakfast" class="ml-3">Ich bin beim
          <router-link to="/?section=ablauf" class="underline">Frühstück</router-link>
          am Sonntag dabei.</label>
        <label v-else for="breakfast" class="ml-3">Wir sind beim
          <router-link to="/?section=ablauf" class="underline">Frühstück</router-link>
          am Sonntag dabei.</label>
      </div>
      <div v-if="breakfast" class="mt-3">
        <label for="breakfast-count">Teilnehmer Frühstück: {{ breakfastCount }}</label> <br>
        <input v-model="breakfastCount" type="range" id="breakfast-count" class="w-full accent-wedding-green"
               name="breakfastCount" min="0" :max="persons.length"/>
      </div>
    </div>

    <div class="form-group">
      <label for="miscellaneous" class="block mb-2">Sonstiges</label>
      <textarea v-model="miscellaneous" id="miscellaneous" name="miscellaneous" class="w-full" rows="4">
    </textarea>
    </div>

    <div class="form-group">
      <input type="submit" value="Absenden" class="w-full bg-wedding-green text-white py-2 px-4 font-bold text-wh"/>
    </div>
  </form>
</template>

<style lang="scss" scoped>
form, input, textarea {
  @apply text-lg md:text-xl lg:text-2xl;
  @apply text-wedding-green;

  &[type="submit"] {
    @apply text-white;
  }
}

.form-group {
  @apply py-4;
}

.radio-label {
  @apply block bg-white py-2 px-4 md:px-8 text-center hover:bg-gray-300 hover:text-white;
}

input, textarea {
  @apply border-0 active:ring-wedding-green focus:ring-wedding-green;
}
</style>
