<script lang="ts" setup>
import {defineProps} from "vue";

const props = defineProps<{
  time: string,
  title: string,
  description?: string,
}>()
</script>

<template>
  <div class="time-element">
    <div class="time">
      {{ props.time }} Uhr
    </div>
    <div class="title">
      {{ props.title }}
    </div>
    <div class="description">
      {{ props.description ?? '' }}
    </div>
  </div>
</template>

<style lang="scss">
.time-element {
  @apply flex flex-col py-4 lg:py-6;

  .time{
    @apply text-2xl lg:text-4xl font-semibold;
  }

  .description, .title {
    @apply text-xl lg:text-2xl;
  }
}
</style>
